<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">提现明细</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/myPurse' }">我的钱包</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/withdrawdeposit' }"
            >申请提现</el-breadcrumb-item
          >
          <el-breadcrumb-item>提现明细</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="py-35 purseBox_cont">
      <el-table :data="list" style="width: 100%" height="250">
        <el-table-column label="提现至">
          <template>
            <div>银行卡</div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="提现时间"> </el-table-column>
        <el-table-column prop="balance" label="提现金额">
          <template slot-scope="scope">
            <div>-{{ scope.row.balance }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex align-center justify-center py-50 border-top border-ligit-BFBFBF">
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      list: [],
      page: 1,
      page_nums: 0,
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/cashLog", {
          page: that.page,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.list = res.data.data;
          that.page_nums = res.data.last_page;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
  &_cont {
    ::v-deep .el-table--fit {
      border-right: 1px solid #ebeef5;
      border-left: 1px solid #ebeef5;
      border-top: 1px solid #ebeef5;
    }
  }
}
</style>
